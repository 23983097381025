@import "primeicons/primeicons.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans_10pt-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Nunito Sans", sans-serif;
  padding: 0;
  margin: 0;
}

p-sidebar {
  .p-sidebar {
    width: auto !important;
  }

  div.p-sidebar-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(229, 231, 235);
  }

  .user-setting-sidebar {
    width: 400px !important;
  }
}

.custom-tired-menu {
  margin-top: 15px !important;
  width: auto !important;
  padding: 10px !important;

  .custom-menu-item div a, .custom-menu-name div a {
    background: white !important;
  }

  .custom-menu-name div a {
    cursor: default;
  }
  
  .custom-menu-item div:hover {
    border-radius: 5px !important;
  
    a {
      background: #bdc5d133 !important;
      border-radius: 5px !important;
    }
  }
}

.custom-dropdown, .report-dropdown, .cu-dropdown {
  width: 100%;
  border: 1px solid #293445;
  box-shadow: none !important;
}

.report-dropdown, .cu-dropdown {
  width: 450px;

  ::ng-deep p-overlay input.p-dropdown-filter {
    box-shadow: none !important;
  }
}

.cu-dropdown {
  height: 42px !important;

  .p-dropdown-label {
    display: flex;
    align-items: center;
  }
}

* {
  input.p-component {
    box-shadow: 0 0 0 0.1rem #bdc5d133 !important;
    text-indent: 5px;
  }
}

.p-datatable-wrapper {
  max-height: calc(100vh - 340px);
}

.custom-table .p-datatable-wrapper {
  border: 1px solid rgb(229, 231, 235) !important;
  border-radius: 10px !important;
}

.role-dropdown {
  width: 100%;
  border: 1px solid rgb(229, 231, 235);
  box-shadow: none !important;
  height: 34px;

  .p-dropdown-label {
    display: flex;
    align-items: center;
  }
}

.multi-dropdown {
  width: 100%;
  border: 1px solid rgb(229, 231, 235);
  box-shadow: none !important;
  height: 34px;

  .p-multiselect-label-container .p-multiselect-label {
    display: flex;
    align-items: center;
  }
}

p-multiselectitem > li > .p-checkbox > .p-checkbox-box, .p-multiselect-header > .p-checkbox > .p-checkbox-box  {
  border: 1px solid rgb(229, 231, 235) !important;
}

.p-multiselect-item {
  border-top: 1px solid rgb(229, 231, 235) !important;
}

.p-multiselect-header {
  height: 40px !important;
}

.bullet-point::before {
  content:"• ";
}

.custom-overlay-panel {
  width: 50%;
  transform: translateX(50%);
}